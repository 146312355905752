import React from "react"

import { ThumbSlide } from "../../../styles/FrontPageFlavorStyles"

import { MilkshakeVanillaBlissThumb } from "./MilkshakeVanillaBliss"
import { MilkshakeChocolateChillThumb } from "./MilkshakeChocolateChill"
import { MilkshakeReesesPBCupThumb } from "./MilkshakeReesesPBCup"
import { MilkshakeOreoCookiesCreamThumb } from "./MilkshakeOreoCookiesCream"
import { MilkshakeMintChipThumb } from "./MilkshakeMintChip"
import { SmoothieStrawberryBananaThumb } from "./SmoothieStrawberryBanana"
import { SmoothieMangoThumb } from "./SmoothieMango"
import { MilkshakeCottonCandyThumb } from "./MilkshakeCottonCandy"

export default function FrenchThumbs(props) {
  const { activeIndex, setActiveIndex } = props

  return (
    <>
      <ThumbSlide onClick={e => setActiveIndex(0)}>
        <MilkshakeCottonCandyThumb active={activeIndex === 0} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(1)}>
        <MilkshakeVanillaBlissThumb active={activeIndex === 1} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(2)}>
        <MilkshakeChocolateChillThumb active={activeIndex === 2} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(3)}>
        <MilkshakeMintChipThumb active={activeIndex === 3} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(4)}>
        <MilkshakeReesesPBCupThumb active={activeIndex === 4} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(5)}>
        <MilkshakeOreoCookiesCreamThumb active={activeIndex === 5} />
      </ThumbSlide>

      <ThumbSlide onClick={e => setActiveIndex(6)}>
        <SmoothieStrawberryBananaThumb active={activeIndex === 6} />
      </ThumbSlide>
      <ThumbSlide onClick={e => setActiveIndex(7)}>
        <SmoothieMangoThumb active={activeIndex === 7} />
      </ThumbSlide>
    </>
  )
}
