import React from "react"

import { FlavorSlide } from "../../../styles/FrontPageFlavorStyles"

import MilkshakeVanillaBlissCup from "./MilkshakeVanillaBliss"
import MilkshakeChocolateChillCup from "./MilkshakeChocolateChill"
import MilkshakeReesesPBCupCup from "./MilkshakeReesesPBCup"
import MilkshakeOreoCookiesCreamCup from "./MilkshakeOreoCookiesCream"
import MilkshakeMintChipCup from "./MilkshakeMintChip"

import SmoothieStrawberryBananaCup from "./SmoothieStrawberryBanana"
import SmoothieMangoCup from "./SmoothieMango"
import MilkshakeCottonCandyCup from "./MilkshakeCottonCandy"

export default function EnglishSlides(props) {
  const { activeIndex } = props

  return (
    <>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 0}>
        <MilkshakeCottonCandyCup />
      </FlavorSlide>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 1}>
        <MilkshakeVanillaBlissCup />
      </FlavorSlide>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 2}>
        <MilkshakeChocolateChillCup />
      </FlavorSlide>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 3}>
        <MilkshakeMintChipCup />
      </FlavorSlide>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 4}>
        <MilkshakeReesesPBCupCup />
      </FlavorSlide>
      <FlavorSlide data-type="milkshakes" data-active={activeIndex === 5}>
        <MilkshakeOreoCookiesCreamCup />
      </FlavorSlide>

      <FlavorSlide data-type="smoothies" data-active={activeIndex === 6}>
        <SmoothieStrawberryBananaCup />
      </FlavorSlide>
      <FlavorSlide data-type="smoothies" data-active={activeIndex === 7}>
        <SmoothieMangoCup />
      </FlavorSlide>
    </>
  )
}
