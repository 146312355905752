import React, { useState } from "react"

import { useTranslateValue } from "../../../data/TranslateContext"

import ArrowRight from "../../../images/arrow-right.svg"
import {
  FlavorRow,
  ThumbRow,
  NextButton,
  PrevButton,
  Wrapper,
} from "../../../styles/FrontPageFlavorStyles"

import EnglishSlides from "./EnglishSlides"
import FrenchSlides from "./FrenchSlides"
import EnglishThumbs from "./EnglishThumbs"
import FrenchThumbs from "./FrenchThumbs"

export default function HowItWorks() {
  const [{ language }] = useTranslateValue()

  const flavorCount = 8 - 1
  const startIndex = 3

  const [activeIndex, setActiveIndex] = useState(startIndex)

  if (activeIndex > flavorCount) {
    setActiveIndex(startIndex)
  }

  const changeFlavor = direction => {
    if (direction === "next") {
      const newIndex = activeIndex + 1
      if (newIndex <= flavorCount) {
        setActiveIndex(newIndex)
      }
    } else if (direction === "prev") {
      const newIndex = activeIndex - 1
      if (newIndex >= 0) {
        setActiveIndex(newIndex)
      }
    }
  }

  return (
    <Wrapper>
      <FlavorRow>
        <PrevButton
          aria-label="Previous Flavor"
          disabled={activeIndex <= 0}
          onClick={e => changeFlavor("prev")}
        >
          <ArrowRight />
        </PrevButton>
        <NextButton
          aria-label="Next Flavor"
          disabled={activeIndex >= flavorCount}
          onClick={e => changeFlavor("next")}
        >
          <ArrowRight />
        </NextButton>

        {language === "en" && <EnglishSlides activeIndex={activeIndex} />}
        {language === "fr" && <FrenchSlides activeIndex={activeIndex} />}
      </FlavorRow>

      <ThumbRow>
        {language === "en" && (
          <EnglishThumbs
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
        {language === "fr" && (
          <FrenchThumbs
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        )}
      </ThumbRow>
    </Wrapper>
  )
}
